// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px


.hero-body {
  padding-bottom: 0px;
}

.post-job-button {
  background-color: $blue;
}

.columns {
  align-items: center;
}
.text-column {
  padding-bottom: 3rem;
}
.right-image {
  background-image: url('../../assets/marketing/desktop/back-image-8.png');
  background-size: 96%;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
}

.group {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
  flex: 1 0 auto;
}

.top-area {
  color: var(--dark-1);
}

.title-large {
  color: currentColor;
  font-family: var(--font-serif);
  font-size: var(--font-2xl);
  font-weight: var(--font-semibold);
  line-height: var(--line-xl);
  margin-bottom: var(--spacing-3);

  @media screen and (min-width: $break-max-phone) {
    margin-bottom: var(--spacing-2);
  }
}

.card-flat {
  background: var(--white);
  border-radius: var(--rounded);
  border: var(--card-flat);
  padding: var(--spacing-4);
  color: var(--dark-3);
  max-width: 27.5rem; // 440px from designs
}

.cite {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-3);
}

.small-image {
  --size: 1.875rem; // 30px from designs

  border-radius: 50%;
  height: var(--size);
  margin-right: var(--spacing-3);
  object-fit: cover;
  width: var(--size);
}

.caption-bold {
  color: currentColor;
  font-family: var(--font-sans);
  font-size: var(--font-sm);
  font-weight: var(--font-medium);
  line-height: var(--line-xs);
}

.paragraph {
  color: currentColor;
  font-family: var(--font-sans);
  font-size: var(--font-base);
  font-weight: var(--font-regular);
  line-height: var(--line-base);
}

.band {
  background-color: $light-grey;
}

.step {
  font-size: var(--font-sm);
  color: var(--dark-1);
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-top: var(--spacing-3);

  @media screen and (min-width: $break-max-phone) {
    margin-top: var(--spacing-4);
  }
}

.actions {
  display: flex;
  flex-direction: column-reverse;
  flex: 1 0 auto;
  justify-content: flex-start;

  @media screen and (min-width: $break-max-phone + 1) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  & .button {
    all: unset;
  }

  & .button.button-primary {
    background-color: var(--blue-1);
    border-radius: var(--rounded);
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    display: block;
    font-family: var(--font-sans);
    font-size: var(--font-base);
    font-weight: var(--font-medium);
    line-height: var(--line-base);
    padding: var(--spacing-2) var(--spacing-3);
    position: relative;
    text-align: center;
    width: 100%;
    margin-top: var(--spacing-3);

    @media screen and (min-width: $break-max-phone + 1) {
      display: inline-block;
      width: initial;
      text-align: left;
      margin-top: initial;
      margin-bottom: var(--spacing-2);
    }

    &.is-loading::after {
      height: 0.75rem;
      width: 0.75rem;
    }

    &:disabled {
      background-color: var(--dark-6);
      cursor: not-allowed;
    }

    &:focus {
      background-color: var(--blue-4);
    }
  }
}
