// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px


.top-container {
  flex-grow: 1;
  display: flex;
}
.full-section {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50%;
  height: 85vh;

  @media screen and (max-width: $break-max-phone) {
    min-height: calc(100vh - 75px);
    height: auto;
    background-size: 100%;
  }

  background-color: $white;

  /* This is how long the background fade transition
    takes. May want to time it with slider transition time!*/
  transition: background-image 1000ms ease-out;

  .top-columns {
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $break-max-phone) {
      flex-direction: column;
      justify-content: flex-start;
      .card {
        margin-top: 1rem;
      }
    }
  }
}

.button {
  margin-top: 1rem;
  margin-right: .6rem;
}

.logo-section {
  @media screen and (max-width: $break-max-tablet) {
    .column.is-one-fifth {
      padding: 0.6rem 0rem;
    }
  }
}
.logo-section {
  padding: 2%;
  background: rgba(0, 0, 0, 0.025);
  background-position: bottom;
  @media screen and (max-width: $break-max-desktop-plus) {
    padding: 2% 0%;
  }
  .logos {
    width: 50%;
  }
  img {
    width: 9rem;
  }
}
.card {
  padding: 0rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.02), 0px 2px 34px 0px rgba(0, 0, 0, 0.1);
}

@for $i from 1 through 5 {
  .background-#{$i} {
    background-image: url('../../assets/marketing/desktop/hero-image-#{$i}.png');
    @media screen and (max-width: $break-max-phone) {
      background-image: url('../../assets/marketing/desktop/mobile-hero-#{$i}.png');
    }
  }
}
